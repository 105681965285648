#advertising-block {
    height: 145px;
    text-align: left;
}

#advertising-block div.lots-block{
    display: inline-grid;
    height: 135px;
    width: 320px;
    border-color: rgb(33, 86, 43) !important
}

#advertising-block div.lots-block div.row.no-gutters{
    height: 108px;
}


#advertising-block img.preview.img-fluid.img-thumbnail.shadow {
    height: 125px;
    max-height: 110px;
    width: 100px;
    object-fit: contain;
    margin: 0 auto;
}

#advertising-block .product-list-img:hover .product-quickview {
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
}

#advertising-block .product-quickview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
    margin-top: 20px;
}

.product-list-img .product-quickview a:hover {
    background-color: #ff3535;
    color: #ffffff;
}

.product-list-img .product-quickview a {
    width: 46px;
    height: 46px;
    background-color: #fff;
    font-size: 20px;
    color: #000000;
    display: inline-block;
    text-align: center;
    line-height: 52px;
    border-radius: 5px;
    opacity: 0.9;
    text-decoration: none;
}