.modal.d-block.show {

    height: auto;
    top: 10%;

}
.modal.d-block.show .modal-dialog{
    margin: 0 auto;
    padding: 20px 35px 0 35px;
}

@media (min-width: 576px) {
    .modal.d-block.show {
        left: 0%;
        top: 5%;
    }
}


@media (min-width: 768px) {
    .modal.d-block.show {
        width: 60%;
        left: 40%;
        top: 30%;
    }
}

@media (min-width: 992px) {
    .modal.d-block.show {
        left: 40%;
        width: 45%;
        top: 30%;
    }
}
@media (min-width: 1200px) {
    .modal.d-block.show {
        width: 40%;
        left: 40%;
        top: 10%;
    }

}

@media (min-width: 1300px) {
    .modal.d-block.show {
        width: 40%;
        left: 15%;
        top: 5%;
    }

}

@media (min-width: 1640px) {
    .modal.d-block.show {
        width: 30%;
        left: 30%;
        top: 10%;
    }

}

@media (min-width: 2160px) {
    .modal.d-block.show {
        width: 25%;
        left: 30%;
        top: 10%;
    }
}

#modal_lots_action.modal.d-block.show input.form-control-sm:focus{
background-color: rgb(255,255,240);
}

