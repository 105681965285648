@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 2;
        column-count: 2
    }
}
/* 3 ������� �� md */
@media (min-width: 768px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3
    }
}
/* 4 ������� �� lg � xl */
@media (min-width: 992px) {
    .card-columns {
        -webkit-column-count: 4;
        column-count: 3
    }
}

.bootstrapiso #list .row.align-items-center:hover,
.bootstrapiso #menu .shop-list-wrap.container-fluid.mb-30:hover,
.bootstrapiso .ht-products .ht-product .ht-product-inner:hover
{
    box-shadow: inset 0 0 0 1px rgba(113, 160, 129, 0.75) !important;
}

.bootstrapiso span.user_name a.username {
    color: rgb(33, 86, 43) !important;
}
