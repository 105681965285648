/** {*/
/*    font-family: Tahoma, sans-serif;*/
/*}*/


/*body {*/
/*    margin: 0;*/
/*    font: 13px/1.231 arial,helvetica,clean,sans-serif;*/
/*    font-family: Tahoma, sans-serif !important;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*    background: rgb(192, 192, 192);*/
/*    box-shadow: 0 5px 15px #000;*/
/*}*/

/*code {*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

.bootstrapiso .navbar.navbar-expand-lg.navbar-dark.bg-success, .bootstrapiso .btn-rg{
    background-color: rgb(33, 86, 43) !important;
}

.bootstrapiso .muted a, .bootstrapiso .muted span {
    color: rgb(102,102,102);
    font-size: 11px;
}

.bootstrapiso .text-rg{
    color: rgb(33, 86, 43) !important;
}


.bootstrapiso .btn-rg, .bootstrapiso .btn-rr, .bootstrapiso .border-rg{
    border-color: rgb(33, 86, 43) !important;
}
.bootstrapiso .btn-rr{
    background-color: rgb(187, 21, 21) !important;
}

.bootstrapiso .section-main {
    border: 1px solid #76947f;
    border-radius: 5px;
    background-color: #E9E9E9;
    box-sizing: border-box;
}

.bootstrapiso .bg-rgrey{
    background-color: #E9E9E9 !important;
}

.bootstrapiso .bg-rgreen{
    background-color: rgb(33, 86, 43) !important;
}
.username.invisible{
    visibility: visible !important;
    color: rgb(163, 190, 148);
}

.modal-open {
    overflow: hidden !important;
}


/*��������*/

.avatar-container{
    width: auto;
    height: auto;
    float: left;
}

.avatar-container img{
    height: 32px;
    width: 32px;
    object-fit: cover
}

.avatar-container a:hover{
    text-decoration: none;
}

.color-rg {
    color: rgb(33, 86, 43) !important;;
}





