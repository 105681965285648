.bootstrapiso .img-modal .modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    opacity: 1;
}

.modal-black-background .modal-content {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.5), 0 0 0 1px #3c3c3c inset, 0 0 3px 1px rgba(0, 0, 0, 0.2) inset;
    background-color: rgba(51, 51, 51, 0.9);
    color: rgb(224, 224, 224);
    z-index: 10000;
    opacity: 0.97;
}

.modal-black-background .modal-header, .modal-black-background .modal-footer {

    background: rgb(51, 51, 51) url('https://reibert.info/styles/soft_responsive/xenforo/layout/pattern.png') repeat 0 0;
}

.modal-black-background .modal-header {
    border-bottom: 1px solid #020202;
}

.modal-black-background .modal-footer {
    border-top: 1px solid #020202;
}

.bootstrapiso .modal-black-background .modal-body {
    clear: both;
    position: relative;
    min-height: 40px;
    border: none;
    border-width: 1px 0;
    background: rgb(51, 51, 51) url('https://reibert.info/styles/soft_responsive/xenforo/layout/pattern.png') repeat 0 0;
}


.bootstrapiso  .close{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-shadow: 0 3px 0 #000;
}

.bootstrapiso .close:hover {
    color: #ff0606;
    text-decoration: none;
    text-shadow: 0 2px 0 #000;
}


.modal-black-background .form-row, .default-form-content p.title.h4 {
    color: rgb(255, 255, 255);;
}