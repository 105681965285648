#view_category_nav div.d-flex.flex-column.mb-2.alert.alert-secondary {
    border-color: rgb(33, 86, 43);
}

#view_category_nav a {
    color: rgb(33, 86, 43);
}


#view_category_nav div.col-12.col-sm-12.col-md-4.col-lg-4.col-xl-4 {
    display: inline-grid;
}

.bootstrapiso #view_category_nav a:hover {
    color: #ff3535;
    text-decoration: none;
}

.bootstrapiso #view_category_nav a{
    font-size: 110%;
}

#view_category_nav div.d-flex.flex-column.mb-2.alert.alert-secondary:hover {
    background-color: white;
}

#view_category_nav .text-muted{
    font-size: 11px;
}
