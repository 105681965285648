
/*.bootstrapiso span.list-badge{*/
/*    font-size: 65%;*/
/*}*/

.bootstrapiso a.lot-title{
    font-weight: bold;
    color: rgb(33,86,43);
    font-size: 110%;
}

/*.bootstrapiso #grid a.lot-title{*/
/*    height: 35.2px;*/
/*    width: 444px;*/
/*    font-size: 110% !important;*/
/*}*/