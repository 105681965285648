.bootstrapiso .page-item.active .page-link {
    z-index: 1;
    color: #fff !important;
    background-color: rgb(33, 86, 43) !important;
    border-color: rgb(33, 86, 43) !important;
}

.bootstrapiso .pagination-sm .page-link {
    color: rgb(33, 86, 43) !important;
    margin-right: 3px;
    margin-left: 0px;
    font-size: 14px;
}

.bootstrapiso .pagination-count-page {
    font-size: 12px;
}
