.bootstrapiso .shop-list-wrap .shop-list-content-firs h3 a:hover {
    color: #ff3535;
}

.bootstrapiso a[href].lot-title:hover{
    color: rgb(187, 21, 21);
    text-decoration: underline;
}

span.item-category-link{
    color:  rgb(102,102,102);
}

.bootstrapiso .max-w-none{
    max-width: fit-content !important;
}

.bootstrapiso .shop-list-wrap .product-list-img a img {
    width: auto !important;
    max-height: 10rem;
}

.bootstrapiso #list .d-flex.admin-lot-button .text-muted.btn.btn-link{
    padding-left: 0rem;
}







