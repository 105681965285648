.react-ios-time-picker-popup {
    top: 50% !important;
    bottom: 50% !important;
}

.bootstrapiso.block-scroll {
    position: fixed;
}

.react-ios-time-picker-btn {
    color: #fff;
}

.set-end-time .dropdown-menu.show{
    overflow-y: scroll;
    height: 150px;
}





