.yarl__container {
	background: transparent url("https://reibert.info/styles/soft_responsive/xenforo/gradients/lightbox-pinstripes.png");
	background-color: transparent;
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-attachment: scroll;
	background-image: url("https://reibert.info/styles/soft_responsive/xenforo/gradients/lightbox-pinstripes.png");
	background-size: auto;
	background-origin: padding-box;
	background-clip: border-box;
	--yarl__swipe_offset: 0px;
}
