.ql-editor {
	height: 8em;
	max-height: 16em;
	background-color: #fff;
	overflow: auto;
}

.ql-toolbar.ql-snow {
	background-color: #fff;
}




@media screen and (max-width: 768px) {
	.ck-toolbar {
		display: none !important;
	}
}