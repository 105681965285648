@media (min-width: 576px) and (max-width: 767.98px) {
    .bootstrapiso .lotShopCards.card-deck {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .bootstrapiso .lotShopCards.card-deck .card {
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        margin-bottom: 2%;
    }
}

.w-90{
    width: 90%;
}

.bootstrapiso .on-selected-lot {
    background-color: rgb(255, 255, 200) !important;
}

