span.card-icon{
    font-size: 35px;
}

span#card{
    font-weight: bold;
}

span.card-icon {
    cursor: pointer;
}