.bootstrapiso div.category-line-link-block > span {
    position: relative;
    font-size: 11px;
    color: #444444;
    font-family: Tahoma, sans-serif;
    padding-right: 12px;
}

.bootstrapiso div.category-line-link-block > span:after {
    position: absolute;
    content: "";
    left: auto;
    right: 3px;
    top: 6px;
    background-color: #949494;
    width: 5px;
    height: 1px;
}

.bootstrapiso div.category-line-link-block > span:last-child:after {
    content: none !important;
}

.bootstrapiso .category-line-link-block.muted .item-category-link a:hover {
    color: rgb(33, 86, 43) !important;
}