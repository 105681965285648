@keyframes ldio-iwpoaph9ugi-o {
    0%    { opacity: 1; transform: translate(0 0) }
    49.99% { opacity: 1; transform: translate(65.28px,0) }
    50%    { opacity: 0; transform: translate(65.28px,0) }
    100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-iwpoaph9ugi {
    0% { transform: translate(0,0) }
    50% { transform: translate(65.28px,0) }
    100% { transform: translate(0,0) }
}
.ldio-iwpoaph9ugi div {
    position: absolute;
    width: 30.28px;
    height: 30.28px;
    border-radius: 50%;
    top: 69.36px;
    left: 36.72px;
}
.ldio-iwpoaph9ugi div:nth-child(1) {
    background: #21562b;
    animation: ldio-iwpoaph9ugi 1.4492753623188404s linear infinite;
    animation-delay: -0.7246376811594202s;
}
.ldio-iwpoaph9ugi div:nth-child(2) {
    background: #dc3545;
    animation: ldio-iwpoaph9ugi 1.4492753623188404s linear infinite;
    animation-delay: 0s;
}
.ldio-iwpoaph9ugi div:nth-child(3) {
    background: #21562b;
    animation: ldio-iwpoaph9ugi-o 1.4492753623188404s linear infinite;
    animation-delay: -0.7246376811594202s;
}
.loadingio-spinner-dual-ball-ac0bevtdmit {
    width: 204px;
    height: 204px;
    display: inline-block;
    overflow: hidden;
    background: rgba(NaN, NaN, NaN, 0);
}

.loadingio-spinner-dual-ball-ac0bevtdmit {
    margin: 0 auto;
}

.ldio-iwpoaph9ugi {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-iwpoaph9ugi div { box-sizing: content-box; }